.container {
  display: grid;
  grid-template-columns: 10vw 10vw 10vw 10vw 10vw 10vw 10vw 10vw 10vw 10vw;
  grid-template-rows: 10vh 10vh 10vh 10vh 10vh 10vh 10vh 10vh 10vh 10vh;
  grid-template-areas:
    ". . . . . . . . . ."
    "portrait . . . . . . . . ."
    "portrait . . . . . . . . ."
    "portrait . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    "chatWindow chatWindow . . . . . . . ."
    "chatWindow chatWindow . . . . . . . ."
    "chatWindow chatWindow . quickBar quickBar quickBar quickBar . optionsBar optionsBar";
}

.portrait {
  grid-area: portrait;
  /* background-color: blueviolet; */
  background-image: url(./Assets/Portrait/Knight_7.png);
  background-size: contain;
  background-repeat: no-repeat;
  /* width: 100%; */
  /* height: 100%; */
  z-index: 1;
}

.quickBar {
  grid-area: quickBar;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  z-index: 1;
}

.actionContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}

.action {
  flex: 0 1 12%;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}

.actionNum {
}

/* Ensures the user cannot select the text */
p {
  user-select: none;
}

.optionsBar {
  grid-area: optionsBar;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  z-index: 1;
}

.optionsContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}

.setting {
  flex: 0 1 24%;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(./Assets/Frames/Frame_Stone_01.png);
}

.chatWindow {
  grid-area: chatWindow;
  background-color: gray;
  z-index: 1;
}

.inventory {
  width: 100vw;
  height: 100vh;
  display: none;
  grid-template-columns: 10vw 10vw 10vw 10vw 10vw 10vw 10vw 10vw 10vw 10vw;
  grid-template-rows: 10vh 10vh 10vh 10vh 10vh 10vh 10vh 10vh 10vh 10vh;
  grid-template-areas:
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . inventorySlots inventorySlots inventorySlots inventorySlots inventorySlots inventorySlots . ."
    ". . inventorySlots inventorySlots inventorySlots inventorySlots inventorySlots inventorySlots . ."
    ". . inventorySlots inventorySlots inventorySlots inventorySlots inventorySlots inventorySlots . ."
    ". . inventorySlots inventorySlots inventorySlots inventorySlots inventorySlots inventorySlots . ."
    ". . inventorySlots inventorySlots inventorySlots inventorySlots inventorySlots inventorySlots . ."
    ". . inventorySlots inventorySlots inventorySlots inventorySlots inventorySlots inventorySlots . ."
    ". . . . . . . . . ."
    ". . . . . . . . . .";
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5030344901632529) 0%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0.4974322492668942) 100%
  );
  z-index: 2;
}

.inventorySlots {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  grid-area: inventorySlots;
}

.inventoryItem {
  flex: 0 1 5%;
  background-image: url(./Assets/Frames/Frame_Stone_01.png);
  background-size: contain;
  height: 50px;
  background-repeat: no-repeat;
}
